import request from '@/utils/request'

/**
 * 添加
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const add = (data) => {
    return request({
        url: '/menus/add',
        method: 'post',
        data: data
    })
}

/**
 * 查询
 * @param query
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getList(data) {
    return request({
        url: '/menus/getList',
        method: 'post',
        data: data
    })
}

/**
 * 删除数据
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteBatch(data) {
    return request({
        url: '/menus/deleteBatch',
        method: 'get',
        params: data
    })
}

/**
 * 修改数据
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const edit = (data) => {
    return request({
        url: '/menus/edit',
        method: 'post',
        data: data
    })
}

// 获取路由
export const getRouters = () => {
    return request({
        url: '/menus/getRouters',
        method: 'get'
    })
}

export const getUserRouters = () => {
    return request({
        url: '/menus/getUserRouters',
        method: 'get'
    })
}

/**
 * 根据id获取数据
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfo(id) {
    return request({
        url: '/menus/getInfo',
        method: 'get',
        params: {id: id}
    })
}

export function getButtons(menuId) {
    return request({
        url: '/menus/getButtons',
        method: 'get',
        params: {menuId: menuId}
    })
}


export function getRouterButtons() {
    return request({
        url: '/menus/getRouterButtons',
        method: 'get',
    })
}
