import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import MyUpload from './components/MyUpload'

Vue.component("MyUpload", MyUpload)
Vue.use(Element, {
    size: 'medium' // set element-ui default size
})
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
