<template>
  <el-container class="m-container">
    <el-aside class="m-aside" :class="isCollapse?'collapse':''">
      <el-scrollbar>
        <div class="side-menu-wrapper">
          <div class="logo-small" v-if="isCollapse">
            <img src="../assets/small-logo.png"/>
          </div>
          <div class="logo" v-else>
            华饮宝后台
          </div>
          <el-menu
              background-color="#304156"
              active-text-color="#409EFF"
              text-color="#fff"
              mode="vertical"
              :collapse="isCollapse"
              :unique-opened="true"
              :collapse-transition="false"
              :default-active="$route.fullPath"
              router>
            <el-submenu :index="item.url" v-for="item in treeData" v-bind:key="item.id">
              <template #title><i :class="item.icon"></i>
                <span>{{ item.label }}</span>
              </template>
              <el-menu-item :index="child.url+'?menuNo='+child.id" v-for="child in item.children" v-bind:key="child.id">
                <i class="el-icon-tickets"></i>{{ child.label }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-container class="r-container">
      <el-header class="header-bar-wrap">
        <div class="header-bar">
          <div class="side-trigger-btn" @click="onCollapse()">
            <i :class="isCollapse?'el-icon-s-fold':'el-icon-s-unfold'"></i>
          </div>
          <div class="custom-bread-crumb">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>
                <a href="/">首页</a>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-for="item in $route.meta['navPath']" :key="item">
                {{ item }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="custom-content-con">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="onUpdatePwd">修改密码</el-dropdown-item>
                  <el-dropdown-item @click.native="onLogout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main class="main-wrap">
        <el-scrollbar class="main-scroll">
          <router-view></router-view>
        </el-scrollbar>
        <el-backtop target=".main-scroll .el-scrollbar__wrap"></el-backtop>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>

import Cookies from "js-cookie";
import {getUserRouters} from "@/api/menu"
import store from "@/store";

export default {
  name: 'Layout',
  data() {
    return {
      isCollapse: false,
      treeData: [],
      username: ""
    }
  },
  created() {
    this.username = Cookies.get("username");
    this.getMenuTree();
    console.log(this.$route.fullPath)
  },
  methods: {
    onLogout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push("/login");
      })
    },
    onUpdatePwd() {

    },
    onCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    getMenuTree() {
      getUserRouters().then((res) => {
        this.treeData = res.data;
      }).catch((res) => {
        console.log(res)
      })
    }
  },
  components: {}
}
</script>
<style lang="scss" scoped>
$bgColor: #f0f2f5;
.logo {
  color: #ffffff;
  font-size: 18px;
}

.logo-small {
  margin-top: 20px;
  margin-left: 20px;
}

.m-container, .r-container {
  background-color: #ffffff;
  height: 100vh
}

.m-aside.collapse {
  width: 64px !important;
}

.m-aside {
  background-color: #304156;
  width: 210px !important;

  .side-menu-wrapper {
    .el-menu {
      border-right: 0;
      width: 100%;

      .el-submenu__title i, .el-menu-item i {
        color: #fff !important;
        opacity: .9;
      }

      .el-menu-item.is-active i {
        color: #409EFF !important;
      }
    }

    .logo {
      margin: 20px auto 20px 25px;
    }
  }
}

.header-bar-wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-shrink: 0;
  flex-direction: column;
  background-color: $bgColor;
  display: flex;
  height: auto !important;
  position: relative;

  .header-bar {
    height: 50px;
    line-height: 50px;
    width: 100%;
    align-items: center;
    display: flex;
    background-color: #fff;

    .side-trigger-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }

    .custom-bread-crumb {
      margin-left: 15px !important;
    }

    .custom-content-con {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: auto;
      cursor: default;
      padding-right: 20px;
    }
  }

  .tags-nav {
    width: calc(100% - 50px);
    overflow-x: hidden;

    .tag-wrap {
      display: flex;
      flex-wrap: nowrap;
      padding: 8px 10px;

      .el-tag {
        min-width: 60px;
        height: 28px;
        font-size: 12px;
        line-height: 28px;
        padding: 0 12px;
        margin-right: 6px;
        border-radius: 2px;
        border: 0;
        text-align: center;
      }
    }

    .tag-menu {
      position: absolute;
      right: 20px;
      top: 65px;
      font-size: 18px;
    }
  }
}

.main-wrap {
  background-color: $bgColor;
  padding: 0 10px 10px 10px;
  flex: 1;
  overflow: hidden;
  background-color: #f2f3f4;
}
</style>
