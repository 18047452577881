import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/Login'),
        hidden: true,

    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error/401'),
        hidden: true
    },
    {
        path: '',
        component: Layout,
        redirect: 'index',
        children: [
            {
                path: 'index',
                name: 'Index',
                component: () => import('@/views/Index'),
                meta: {title: '首页', icon: 'dashboard', affix: true, navPath: ["统计面板"]}
            },
            {
                path: '/admin/list',
                name: 'admin',
                component: () => import('@/views/admin/Index'),
                meta: {title: '系统管理', icon: 'dashboard', affix: true, navPath: ["系统管理", "用户管理"]}
            },
            {
                path: '/role/list',
                name: 'role',
                component: () => import('@/views/role/Index'),
                meta: {title: '系统管理', icon: 'dashboard', affix: true, navPath: ["系统管理", "角色管理"]}
            },
            {
                path: '/advInfo/list',
                name: 'advInfo',
                component: () => import('@/views/advInfo/Index'),
                meta: {title: '广告管理', icon: 'dashboard', affix: true, navPath: ["广告管理", "广告列表"]}
            },
            {
                path: '/advSpace/list',
                name: 'advSpace',
                component: () => import('@/views/advSpace/Index'),
                meta: {title: '广告管理', icon: 'dashboard', affix: true, navPath: ["广告管理", "位置列表"]}
            },
            {
                path: '/users/list',
                name: 'users',
                component: () => import('@/views/users/Index'),
                meta: {title: '场馆管理', icon: 'dashboard', affix: true, navPath: ["用户管理", "用户列表"]}
            },
            {
                path: '/menu/list',
                name: 'menu',
                component: () => import('@/views/menu/Index'),
                meta: {title: '系统管理', navPath: ["系统管理", "菜单管理"]}
            },
            {
                path: '/configInfo/list',
                name: 'configInfo',
                component: () => import('@/views/configInfo/Index'),
                meta: {title: '系统管理', navPath: ["系统管理", "系统配置"]}
            },
            {
                path: '/ordersInvoices/list',
                name: 'ordersInvoices',
                component: () => import('@/views/ordersInvoices/Index'),
                meta: {title: '系统管理', navPath: ["系统管理", "开票管理"]}
            },
            {
                path: '/usersAddress/list',
                name: 'usersAddress',
                component: () => import('@/views/usersAddress/Index'),
                meta: {title: '系统管理', navPath: ["系统管理", "用户地址"]}
            },
            {
                path: '/usersPayment/list',
                name: 'usersPayment',
                component: () => import('@/views/usersPayment/Index'),
                meta: {title: '系统管理', navPath: ["系统管理", "收款方式"]}
            },
            {
                path: '/shops/list',
                name: 'shops',
                component: () => import('@/views/shops/Index'),
                meta: {title: '系统管理', navPath: ["水站管理", "水站列表"]}
            },
            {
                path: '/transactions/list',
                name: 'transactions',
                component: () => import('@/views/transactions/Index'),
                meta: {title: '财务管理', navPath: ["财务管理", "交易流水"]}
            },
            {
                path: '/transactionsDeposit/list',
                name: 'transactionsDeposit',
                component: () => import('@/views/transactionsDeposit/Index'),
                meta: {title: '财务管理', navPath: ["财务管理", "用户充值"]}
            },
            {
                path: '/transactionsWithdraw/list',
                name: 'transactionsWithdraw',
                component: () => import('@/views/transactionsWithdraw/Index'),
                meta: {title: '财务管理', navPath: ["财务管理", "用户提现"]}
            },
            {
                path: '/products/list',
                name: 'products',
                component: () => import('@/views/products/Index'),
                meta: {title: '水站管理', navPath: ["水站管理", "商品列表"]}
            },
            {
                path: '/customWater/list',
                name: 'customWater',
                component: () => import('@/views/customWater/Index'),
                meta: {title: '水站管理', navPath: ["水站管理", "企业定制"]}
            },
            {
                path: '/replys/list',
                name: 'replys',
                component: () => import('@/views/replys/Index'),
                meta: {title: '水站管理', navPath: ["水站管理", "水站评论"]}
            },
            {
                path: '/rebateLevels/list',
                name: 'rebateLevels',
                component: () => import('@/views/rebateLevels/Index'),
                meta: {title: '佣金管理', navPath: ["佣金管理", "佣金层级"]}
            }
            ,
            {
                path: '/rebateRanks/list',
                name: 'rebateRanks',
                component: () => import('@/views/rebateRanks/Index'),
                meta: {title: '佣金管理', navPath: ["佣金管理", "佣金等级"]}
            }
            ,
            {
                path: '/rebateRecords/list',
                name: 'rebateRecords',
                component: () => import('@/views/rebateRecords/Index'),
                meta: {title: '佣金管理', navPath: ["佣金管理", "佣金记录"]}
            },
            {
                path: '/orders/list',
                name: 'orders',
                component: () => import('@/views/orders/Index'),
                meta: {title: '订单管理', navPath: ["订单管理", "订单列表"]}
            },
            {
                path: '/ordersItem/list',
                name: 'ordersItem',
                component: () => import('@/views/ordersItem/Index'),
                meta: {title: '订单管理', navPath: ["订单管理", "订单明细"]}
            },
            {
                path: '/usersInvoices/list',
                name: 'usersInvoices',
                component: () => import('@/views/usersInvoices/Index'),
                meta: {title: '用户管理', navPath: ["用户管理", "发票信息"]}
            },
            {
                path: '/productsCategory/list',
                name: 'productsCategory',
                component: () => import('@/views/productsCategory/Index'),
                meta: {title: '水站管理', navPath: ["水站管理", "商品分类"]}
            },
            {
                path: '/statistics/dayCount',
                name: 'dayCount',
                component: () => import('@/views/statistics/DayCount'),
                meta: {title: '店铺统计', icon: 'dashboard', affix: true, navPath: ["店铺统计", "每日统计"]}
            },
            {
                path: '/statistics/monthCount',
                name: 'monthCount',
                component: () => import('@/views/statistics/MonthCount'),
                meta: {title: '店铺统计', icon: 'dashboard', affix: true, navPath: ["店铺统计", "每月统计"]}
            },
            {
                path: '/statistics/productCount',
                name: 'productCount',
                component: () => import('@/views/statistics/ProductCount'),
                meta: {title: '店铺统计', icon: 'dashboard', affix: true, navPath: ["店铺统计", "商品统计"]}
            }
        ]
    },
]

export const dynamicRoutes = []

const router = new VueRouter({
    routes: constantRoutes,
    scrollBehavior: () => ({y: 0}),
    mode: "hash"
})

export default router
